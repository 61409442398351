<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/cadastro/tag-produto"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <FormField
          label="Nome do agrupador"
          v-model="form.descricao"
          :validation="$v.form.descricao"
        />
      </v-col>
      <v-col cols="12">
        <FormTags
          placeholder="Pesquise e adicione tags"
          label="Pesquise e adicione tags"
          v-model="form.tags"
          :endpoint="endpointTags"
          :validation="$v.form.tags"
          keyid="idtagpesquisa"
          keyvalue="valor"
          :search-with-spaces="true"
          :order="form.local_uso.includes('card_produto')"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex flex-column align-start">
              <p style="margin-bottom: 0 !important">{{ item.text }}</p>
              <small v-if="item.small">Cod.: {{ item.descricao }}</small>
              <small v-if="item.agrupadores"
                >Agr.:{{ item.agrupadores.join('|') }}, Ordem:
                {{ item.ordem }}</small
              >
            </div>
          </template>
        </FormTags>
      </v-col>
      <v-col cols="12">
        <!-- Criar função para além de ocultar campo, limpar valor de form.ordenacao -->
        <FormSelect
          label="Ordenação"
          v-model="form.ordenacao"
          :options="options"
          :validation="$v.form.ordenacao"
          v-if="!form.local_uso.includes('card_produto')"
        />
      </v-col>
      <v-col cols="12" md="auto" class="mx-auto mt-n2">
        <FormSwitch
          v-model="form.multipla_selecao"
          label="Permitir multipla seleção"
        />
      </v-col>
      <v-col cols="8" class="ml-auto">
        <FormSelect
          v-if="form.multipla_selecao"
          label="Operador multipla seleção"
          v-model="form.operador_multipla_selecao"
          :options="agrupadores"
          :validation="$v.form.operador_multipla_selecao"
        />
      </v-col>
      <v-col cols="12">
        <v-card
          outlined
          flat
          v-if="
            form.multipla_selecao && form.operador_multipla_selecao == 'and'
          "
        >
          <v-card-title style="font-size: 22px;">
            Operador Multipla Seleção
            <v-chip class="ml-5 font-weight-bold">E</v-chip>
          </v-card-title>
          <v-divider />
          <v-card-text style="font-size: 18px; ">
            Para realizar a conjunção de tags do mesmo agrupador. Exemplo:
            <br /><br />
            Produto Tênis A tem as tag's de tamanho 41 e 42, já o Tênis B tem a
            tag de tamanho 41. Quando filtrado no commerce para 41 e 42, será
            apresentado somente o produto Tênis A.
          </v-card-text>
        </v-card>
        <v-card
          outlined
          flat
          v-if="form.multipla_selecao && form.operador_multipla_selecao == 'or'"
        >
          <v-card-title style="font-size: 22px;">
            Operador Multipla Seleção
            <v-chip class="ml-5 font-weight-bold">OU</v-chip>
          </v-card-title>
          <v-divider />
          <v-card-text style="font-size: 18px; ">
            Para realizar a disjunção de tags do mesmo agrupador. Exemplo:
            <br /><br />
            Produto Tênis A tem a tag de tamanho 41, já o Tênis B tem a tag de
            tamanho 42. Quando filtrado no commerce para 41 e 42, será
            apresentado os produtos Tênis A e Tênis B.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-3 mb-n5">
        <LineDivider>
          <p class="font-weight-bold" style="font-size: 22px;">Local de uso</p>
        </LineDivider>
      </v-col>
      <v-col cols="12" class="mb-n5">
        <FormCheckbox
          label="Filtro de busca"
          val="filtro_busca"
          v-model="form.local_uso"
        />
      </v-col>
      <v-col cols="12" class="mb-n5">
        <FormCheckbox
          label="Detalhes produto"
          val="detalhe_produto"
          v-model="form.local_uso"
        />
      </v-col>
      <v-col cols="12" class="mb-n5" v-if="isDetalhes">
        <FormSwitch
          v-model="form.onlyAgrupador"
          label="Alterar com base exclusiva no agrupador de embalagem"
        />
      </v-col>
      <v-col cols="12" class="ml-auto">
        <v-row no-gutters>
          <v-col cols="auto">
            <FormCheckbox
              label="Card produto"
              val="card_produto"
              v-model="form.local_uso"
            />
          </v-col>
          <v-col cols="auto">
            <v-tooltip right max-width="25%" content-class="tooltip-content">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon class="ml-3 mt-4" style="cursor: pointer;" v-on="on"
                    >far fa-question-circle</v-icon
                  >
                </div>
              </template>
              <span
                >Será permitido a seleção de apenas um agrupador de tag para
                listagem no Card de produtos.</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import { TAGAGRUPADOR_LOAD } from '@/store/actions/tagsProduto'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import FormCheckbox from '@/components/form/FormCheckbox'
import { required } from 'vuelidate/lib/validators'
import LineDivider from '@/components/LineDivider'
import { mapGetters } from 'vuex'
import FormTags from '@/components/form/FormTags'
import FormSelect from '@/components/form/FormSelect'
import {
  TAGAGRUPADOR_UPDATE,
  TAGAGRUPADOR_CREATE
} from '@/store/actions/tagsProduto'
import FormButton from '@/components/form/FormButton'

export default {
  name: 'TagAgrupadorForm',
  components: {
    SidePopup,
    FormField,
    FormSwitch,
    FormCheckbox,
    LineDivider,
    FormTags,
    FormButton,
    FormSelect
  },
  validations: {
    form: {
      descricao: { required },
      tags: { required }
    }
  },
  data: () => ({
    mode: 'add',
    form: {
      idagrupadortagpesquisa: null,
      descricao: '',
      tags: [],
      local_uso: [],
      multipla_selecao: true,
      ordenacao: 'A-Z',
      onlyAgrupador: false
    },
    options: [
      {
        value: 'A-Z',
        text: 'A-Z'
      },
      {
        value: 'Z-A',
        text: 'Z-A'
      }
    ],
    agrupadores: [
      {
        value: 'and',
        text: 'E'
      },
      {
        value: 'or',
        text: 'Ou'
      }
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    title() {
      return this.mode == 'edit'
        ? 'Editar agrupador de tag'
        : 'Novo agrupador de tag'
    },
    endpointTags() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}`
    },
    isDetalhes() {
      return this.form.local_uso.includes('detalhe_produto')
    }
  },
  methods: {
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/cadastro/tag-produto')
      }
    },
    getData(id) {
      this.$store.dispatch(TAGAGRUPADOR_LOAD, id).then(resp => {
        this.form = {
          ...this.form,
          ...resp,
          local_uso: !resp.local_uso ? ['filtro_busca'] : resp.local_uso,
          onlyAgrupador: resp.only_agrupador
        }
        this.form.tags.sort((a, b) => a.ordem - b.ordem)
      })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (this.mode == 'add') {
        this.$store.dispatch(TAGAGRUPADOR_CREATE, this.form).then(() => {
          this.$vueOnToast.pop(
            'success',
            'Agrupador de tags cadastrado com sucesso'
          )
          this.onClose(true)
        })
      } else {
        this.$store.dispatch(TAGAGRUPADOR_UPDATE, this.form).then(() => {
          this.$vueOnToast.pop(
            'success',
            'Agrupador de tags atualizado com sucesso'
          )
          this.onClose(true)
        })
      }
    }
  },
  created() {
    const idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    }
  }
}
</script>

<style>
.tooltip-content {
  text-align: left;
}
</style>
